import * as React from 'react';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';

const AboutPage = () => {
  return (
    <>
      <Header hideNav={true} />
      <div className="container-fluid p-0">
        <div className="container">
          <div className="row justify-content-center py-5">
            <div className="col-10 d-flex justify-content-center text-center">
              <h2>Privacy policy</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3>Personal identification information</h3>
              <p>
                We may collect personal identification information from you in a
                variety of ways, including, but not limited to, when you visit
                our site, register on the site, subscribe to the newsletter,
                email or call us, and in connection with other activities,
                services, features or resources we make available on our site.
                You may be asked for, as appropriate, name, email address,
                mailing address, phone number. We will collect personal
                identification information from you only if you voluntarily
                submit such information to us. You can always refuse to supply
                personal identification information, except that it may prevent
                you from engaging in certain site-related activities.
              </p>
              <h3>Non-personal identification information</h3>
              <p>
                We may collect non-personal identification information about you
                whenever you interact with our site. We use this information to
                monitor and improve our website. Non-personal identification
                information may include the browser name, the type of computer
                and technical information about your means of connection to our
                site, such as the operating system, browser type and the
                internet service providers utilized, information about your
                visits to and activity on our site including content you view
                and interact with, the address of the website you arrived from
                and other clickstream behavior (including pages you view, links
                you click, the length of your browsing session, your IP
                (Internet Protocol) address and your device’s approximate
                location at the time of browsing). IP addresses are not linked
                to personally identifiable information.
              </p>
              <p>
                {' '}
                We use cookies on our website for the following purposes:
                <ul>
                  <li>To make the website more user-friendly.</li>
                  <li>
                    To analyse website usage and collect statistics on visitors.
                  </li>
                </ul>
              </p>
              <h3>Processing and collection of personal data</h3>
              <p>
                We use your personal data only for the following purposes: To
                send you information emails, press releases and invitations.
                Cloppus collects the following personal data about you: Name,
                email address and phone number. We do so if you subscribe to our
                newsletter or correspond with us.{' '}
              </p>
              <h3>Right to access</h3>
              <p>
                You are at any time entitled to get access to any information we
                have regarding you, what our purposes with the registration is
                and from where we have obtained the information. You have the
                right to get a copy of the personal data we have on you. If you
                want a copy of your personal data, you need to send a written
                request to info@cloppus.com. You can be asked to document that
                you are who you claim to be.{' '}
              </p>
              <h3>Right to deletion</h3>
              <p>
                You have the right to get all or some of your personal
                information deleted by us, you only need to send an email to
                info@cloppus.com.{' '}
              </p>
              <h3>Protection and storage of your personal data</h3>
              <p>
                We take your security seriously, and will always ensure that the
                necessary technical and organisational security measures have
                been implemented to protect your data from unauthorised or
                unlawful processing, loss, alteration, misuse or publication. We
                store your data on equipment with limited access, inside
                controlled facilities. Our security measures are regularly
                monitored to ensure the personal data we process is being
                handled responsibly and in accordance with the data protection
                legislation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
